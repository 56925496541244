(function () {
  'use strict';

  angular
    .module('ecs')
    .config(configApi)
    .config(configTranslate)
    .config(configAcceptLanguage)
    .config(configLocalization)
    .config(configNgMaterial)
    .config(configEnvironment)
    .config(configLoadingBar)
    .config(configDebugLevel)
    .config(addSetNextUrlMethodToLocation)
    .config(addShowToastMethodToToastService)
    .config(allowBlobLinkHrefs)
    .config(['uiMask.ConfigProvider', function (uiMaskConfigProvider) {
      uiMaskConfigProvider.maskDefinitions({'8': /\d?/});
      uiMaskConfigProvider.clearOnBlur(false);
    }]);

  function configApi(appConfig, wbResourceProvider) {
    wbResourceProvider.setBaseUrl(appConfig.api.url);
  }

  function determineLanguage() {
    var language = '';

    if (navigator.languages && navigator.languages.length) {
      language = navigator.languages[0];
    } else {
      language = navigator.language || navigator.browserLanguage || navigator.systemLanguage || navigator.userLanguage || '';
    }

    return language.slice(0, 2);
  }

  function configTranslate($translateProvider, appConfig, $httpProvider) {
    $translateProvider
      .determinePreferredLanguage(function () {
        return determineLanguage() || appConfig.language.fallback;
      });
    $translateProvider.fallbackLanguage(appConfig.language.fallback);
    $translateProvider.useCookieStorage();
  }

  function configAcceptLanguage(AcceptLanguageInterceptorProvider, appConfig, $httpProvider, $translateProvider) {
    $httpProvider.defaults.headers.common = {'Accept-Language': ''};
    AcceptLanguageInterceptorProvider.setPrimaryLanguage(determineLanguage());
    AcceptLanguageInterceptorProvider.setFallbackLanguage(appConfig.language.fallback);
  }

  function configLocalization(tmhDynamicLocaleProvider, appConfig) {
    tmhDynamicLocaleProvider.localeLocationPattern(appConfig.localeLocation);
  }

  function configNgMaterial($mdThemingProvider) {
    $mdThemingProvider
      .theme('wbTheme')
      .primaryPalette('indigo', {
        default: '500',
        'hue-1': '300',
        'hue-2': '200',
        'hue-3': '100'
      })
      .accentPalette('lime', {
        default: '600'
      })
      .warnPalette('red', {
        default: '500'
      })
      .backgroundPalette('grey', {
        default: 'A100',
        'hue-1': '400',
        'hue-2': '300',
        'hue-3': '500'
      });

    $mdThemingProvider.setDefaultTheme('wbTheme');
  }

  function configEnvironment($httpProvider, appConfig) {
    if (appConfig.cors) {
      $httpProvider.defaults.withCredentials = true;
    }
  }

  function configLoadingBar(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
  }

  function configDebugLevel($compileProvider, $logProvider, appConfig) {
    $compileProvider.debugInfoEnabled(appConfig.debug);
    $logProvider.debugEnabled(appConfig.debug);
  }

  function addSetNextUrlMethodToLocation($provide) {
    $provide.decorator('$location', function ($delegate) {
      $delegate.setNextUrl = function setNextUrl(urlToAdd) {
        $delegate.search('next', encodeURIComponent(urlToAdd));
      };

      return $delegate;
    });
  }

  function addShowToastMethodToToastService($provide) {
    $provide.decorator('$mdToast', function ($delegate, $translate) {
      $delegate.showToast = function showToast(content) {
        $delegate.show(
          $delegate.simple()
            .content($translate.instant(content))
            .position('bottom right')
        );
      };

      return $delegate;
    });
  }

  function allowBlobLinkHrefs($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
  }
}());
